import React from 'react'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { Quote } from '../../components/Quote'
import { Helmet } from 'react-helmet'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';
import { CenteredImg, WidthImg } from '../../common/Style';

import SfOnLaptop from '../images/sfOnLaptop.png'
import SfNotes from '../images/sfNotes.svg'
import TeamDashHalf from '../images/teamDashHalf.svg'
import OldWay from '../images/oldWay.svg';
import ActionEvent from '../images/actionEvent.svg';
import SalesforceDashboard from '../images/salesforceDashboard.png'
import BestSalesforce from '../images/bestSalesforce.svg';


const SolutionsPage = () => (
  <Default>
    <Helmet>
      <title>Truly | Scaling Pipeline Management for CROs</title>
    </Helmet>

    <WrapperBackground
      color={colors.trulyDark}
      background='headsetPerson2'
    >
      <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
        <Box width="1">
          <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
            Revenue Process Automation
          </PageTitle2>
          <PageTitle as="p" color={colors.white} mb="1.5rem" center>
            Scale Your Forecasting & Pipeline Management
          </PageTitle>
        </Box>
      </Section>
    </WrapperBackground>




        <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={OldWay} alt="Salesforce opportunity history"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 1: CAPTURE EVERY CHANGE IN PIPELINE
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
           Salesforce's  data structure is great for seeing what your pipeline looks like today, but not for showing how it's changing over time.  This means every coaching/deal review needs to be supplemented with hours of additional discovery (rep anecdotes, checking multiple systems, etc.)
           <br/><br/>
           Truly's RPA package automatically tracks all changes in your Opportunity pipeline, along with all activities, making it easy to report on them in Salesforce in real-time.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
          STEP 2: MODEL THE PIPELINE
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
             Logging pipeline changes is easy.  Making sense of them at scale is hard. 
           <br/><br/>
             The traditional solution to this has been more validation rules in CRM (which reps hate) and overly complex excel spreadsheets (which take a lot of Sales Ops time), both of which lead to incomplete data.
            <br/><br/>
            Truly leverages a proprietary methodology for tracking pipeline movement without requiring any changes to your CRM setup or rep workflow.  All of this data becomes reportable in Salesforce, so you get real-time visibility into how your pipeline is changing, why it's changing and how it's impacting your funnel metrics like stage conversion rates, deal velocity, etc.
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={BestSalesforce} alt="Model your sales activity data"/>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={TeamDashHalf} alt="Forecast your sales"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          STEP 3: FORECAST & ACT
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Now that you know how your pipeline is changing in real-time, you can adjust your forecast day after day, and make recommendations to the C-Level on where urgent investments are needed, so you can hit your number every quarter.

          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperBackground background="headsetPerson1" gradientType="purple">
      <CallToAction/>
    </WrapperBackground>

    <WrapperBackground background="sf" gradientType="black">
      <GetStarted/>
    </WrapperBackground>

  </Default>
)

export default SolutionsPage

